<template>
  <div :class="['row', `${position}-team`]">
    <div class="col-3 d-flex align-items-center justify-content-start team-name">
      <div class="d-flex align-items-center ml-2 mb-2">
        <span class="h5 mt-1 ml-2">
          {{ team_name }}
        </span>
      </div>
      <span>BANK:</span>
    </div>
    <div class="col-9 rounds d-flex align-items-stretch justify-content-start">
      <div
        v-for="(round, i) in rounds"
        :key="i"
        :class="['eco-box', 'd-flex', 'justify-content-center', 'align-items-center', round.win ? 'win' : '', role]"
      >
        {{ round.eco }}
        <span>{{ round.loadout / 1000 }}k</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    position: String,
    team_id: String,
    team_name: String,
    icon_url: String,
    role: String,
    rounds: Array,
  },
}
</script>

<style lang="scss" scoped>
.team-name {
  position: relative;
  flex: 1 0 25%;
  width: 25%;

  > img {
    max-height: 50px;
  }

  > span {
    position: absolute;
    right: 0;

    @extend %font-mono-sm;
  }

  .first-team & > span {
    bottom: 100%;
  }

  .second-team & > span {
    top: 100%;
  }
}

.eco-box {
  flex: 0 0 50px;
  margin: 5px;
  width: 50px;
  height: 50px;
  background-color: $gray-400;
  font-size: 1.25rem;
  color: $gray-700;
  text-shadow: 1px 1px 0 rgb(255 255 255 / 20%);
  font-family: $font-sen;
  font-weight: 400;
  position: relative;

  > span {
    position: absolute;
    text-shadow: none;

    @extend %font-mono-sm;
  }

  .first-team & > span {
    bottom: 100%;
    transform: translateY(-5px);
  }

  .second-team & span {
    top: 100%;
    transform: translateY(5px);
  }

  &.win {
    color: white;
    text-shadow: 1px 1px 0 rgb(0 0 0 / 20%);

    &.def {
      background: $info;
    }

    &.atk {
      background: $danger;
    }
  }
}

.team-logo {
  max-width: 50px;
  max-height: 60px;
}
</style>
