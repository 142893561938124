var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "my-5" },
      [
        _c("MatchChartEconomy", {
          attrs: { economy: _vm.teamsEconomy, type: "scrim", teams: _vm.teams }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mt-5 mb-5" },
      [
        _c("AppTable", {
          attrs: {
            fields: _vm.items_preview_fields,
            items: _vm.economyPreview
          },
          scopedSlots: _vm._u([
            {
              key: "cell(team_name)",
              fn: function(data) {
                return [
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c("span", { staticClass: "h5 mt-1 ml-2" }, [
                      _vm._v(" " + _vm._s(data.value) + " ")
                    ])
                  ])
                ]
              }
            },
            {
              key: "cell(eco)",
              fn: function(data) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(data.item.eco.played) +
                      " (" +
                      _vm._s(data.item.eco.won) +
                      ") "
                  )
                ]
              }
            },
            {
              key: "cell(semi_eco)",
              fn: function(data) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(data.item.semi_eco.played) +
                      " (" +
                      _vm._s(data.item.semi_eco.won) +
                      ") "
                  )
                ]
              }
            },
            {
              key: "cell(semi_buy)",
              fn: function(data) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(data.item.semi_buy.played) +
                      " (" +
                      _vm._s(data.item.semi_buy.won) +
                      ") "
                  )
                ]
              }
            },
            {
              key: "cell(full_buy)",
              fn: function(data) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(data.item.full_buy.played) +
                      " (" +
                      _vm._s(data.item.full_buy.won) +
                      ") "
                  )
                ]
              }
            }
          ])
        })
      ],
      1
    ),
    _vm._m(0),
    _c(
      "div",
      { staticClass: "pb-5 mb-5" },
      _vm._l(_vm.economyFirstHalf, function(team, i) {
        return _c("ScrimEconomyTableRow", {
          key: team.id,
          attrs: {
            position: i === 0 ? "first" : "second",
            team_id: team.id,
            team_name: team.name,
            icon_url: team.icon_url,
            role: team.role,
            rounds: team.rounds
          }
        })
      }),
      1
    ),
    _c(
      "div",
      { staticClass: "pb-5 mb-5" },
      _vm._l(_vm.economySecondHalf, function(team, i) {
        return _c("ScrimEconomyTableRow", {
          key: team.id,
          attrs: {
            position: i === 0 ? "first" : "second",
            team_id: team.id,
            team_name: team.name,
            icon_url: team.icon_url,
            role: team.role,
            rounds: team.rounds
          }
        })
      }),
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-5 mb-5 text-center eco-legend" }, [
      _c("span", { staticClass: "mr-5" }, [
        _c("strong", [_vm._v("P")]),
        _vm._v(" Pistol round")
      ]),
      _c("span", { staticClass: "mr-5" }, [
        _c("strong", [_vm._v("$")]),
        _vm._v(" Eco: 0-5k")
      ]),
      _c("span", { staticClass: "mr-5" }, [
        _c("strong", [_vm._v("$$")]),
        _vm._v(" Semi-eco: 5-10k")
      ]),
      _c("span", { staticClass: "mr-5" }, [
        _c("strong", [_vm._v("$$$")]),
        _vm._v(" Semi-buy 10-16.5k")
      ]),
      _c("span", [_c("strong", [_vm._v("$$$$")]), _vm._v(" Full-buy 16.5k+")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }