var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ApiLoadingController", {
    attrs: { fetch: _vm.fetch, params: _vm.params },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var data = ref.data
          return [
            _vm.missingTabData
              ? _c("ErrorAlert", [
                  _vm._v(
                    " Missing economy data for rounds: " +
                      _vm._s(_vm.missingTabDataRounds.join(", ")) +
                      ". Please check if Tab was pressed 5 seconds before beginning of each round. "
                  )
                ])
              : _vm._e(),
            _c("ScrimEconomy", _vm._b({}, "ScrimEconomy", data, false))
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }