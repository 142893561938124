import { render, staticRenderFns } from "./ScrimEconomyPage.vue?vue&type=template&id=41445175&scoped=true&"
import script from "./ScrimEconomyPage.vue?vue&type=script&lang=js&"
export * from "./ScrimEconomyPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41445175",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2891420128/src/web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('41445175')) {
      api.createRecord('41445175', component.options)
    } else {
      api.reload('41445175', component.options)
    }
    module.hot.accept("./ScrimEconomyPage.vue?vue&type=template&id=41445175&scoped=true&", function () {
      api.rerender('41445175', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/scrims/ScrimEconomyPage.vue"
export default component.exports