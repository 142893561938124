import { render, staticRenderFns } from "./ScrimEconomy.vue?vue&type=template&id=330ef6f1&scoped=true&"
import script from "./ScrimEconomy.vue?vue&type=script&lang=js&"
export * from "./ScrimEconomy.vue?vue&type=script&lang=js&"
import style0 from "./ScrimEconomy.vue?vue&type=style&index=0&id=330ef6f1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "330ef6f1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2891420128/src/web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('330ef6f1')) {
      api.createRecord('330ef6f1', component.options)
    } else {
      api.reload('330ef6f1', component.options)
    }
    module.hot.accept("./ScrimEconomy.vue?vue&type=template&id=330ef6f1&scoped=true&", function () {
      api.rerender('330ef6f1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/scrim/ScrimEconomy.vue"
export default component.exports