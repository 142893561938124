<template>
  <ApiLoadingController :fetch="fetch" :params="params">
    <template #default="{ data }">
      <ErrorAlert v-if="missingTabData">
        Missing economy data for rounds: {{ missingTabDataRounds.join(', ') }}. Please check if Tab was pressed 5
        seconds before beginning of each round.
      </ErrorAlert>
      <ScrimEconomy v-bind="data" />
    </template>
  </ApiLoadingController>
</template>

<script>
import px from 'vue-types'
import { mapGetters } from 'vuex'

import { getScrimAdvancedRoundInfo } from '@/api/scrims'
import ApiLoadingController from '@/components/controllers/ApiLoadingController.vue'
import ErrorAlert from '@/components/generic/ErrorAlert.vue'
import ScrimEconomy from '@/components/scrim/ScrimEconomy.vue'
import calcTeamEco from '@/utils/calcTeamEco.js'
import reportException from '@/utils/reportException'

export default {
  name: 'ScrimEconomyPage',
  components: {
    ScrimEconomy,
    ApiLoadingController,
    ErrorAlert,
  },
  props: {
    scrimId: px.string.isRequired,
  },
  data: () => ({
    missingTabDataRounds: [],
  }),
  inject: ['scrimData'],
  computed: {
    ...mapGetters({
      gearsById: 'static/gearsById',
      weaponsById: 'static/weaponsById',
    }),
    params() {
      return this.scrimId
    },
    missingTabData() {
      return this.missingTabDataRounds.length > 0
    },
    scrim() {
      return this.scrimData.scrim
    },
  },
  methods: {
    async fetch(scrimId, options) {
      let failedApiCalls = 0

      const scrimAdvancedData = await Promise.all(
        this.scrimData.scrim.roundData?.map(async round => {
          try {
            const data = await getScrimAdvancedRoundInfo(scrimId, round.round_num, options)
            return data
          } catch (e) {
            failedApiCalls++
            if (!/^Network error 40[3-4]/.test(e.message)) {
              reportException(e)
            }
          }
        })
      )

      return {
        teams: this.scrim?.teams?.map((team, teamIndex) => ({
          ...team,
          name: team.name,
          economy: Array.from({ length: this.scrim.rounds_played }, (_, round_num) => {
            const roundData = scrimAdvancedData?.find(roundData => {
              return roundData.round_num === round_num
            })
            const ecoItem = {
              eco: this.calcEco(team, round_num, roundData.tabtable_detections_expand),
              loadout: this.calcEcoAmount(team, roundData.tabtable_detections_expand),
              ...(this.scrim.round_scores
                ? {
                    win: this.calcRoundWin(team, round_num, this.scrim, [
                      ...this.scrim.round_scores,
                      {
                        left: this.scrim.teams.find(team => team.grid === this.scrim.pov_grid).rounds_won,
                        right: this.scrim.teams.find(team => team.grid !== this.scrim.pov_grid).rounds_won,
                      },
                    ]),
                  }
                : {}),
            }

            if (teamIndex === 0 && ecoItem.eco === null) {
              this.missingTabDataRounds.push(round_num + 1)
            }

            return ecoItem
          }),
        })),
        incompleteMlData: failedApiCalls > 0,
      }
    },
    calcEco(team, roundNum, tabDetections) {
      if (!tabDetections) {
        return null
      }

      return calcTeamEco(this.calcEcoAmount(team, tabDetections), roundNum, true)
    },
    calcEcoAmount(team, tabDetections) {
      if (!tabDetections?.loadouts) {
        return null
      }

      return team.players.reduce((acc, player) => {
        const playerTabData = tabDetections.loadouts.find(item => item.puuid === player.puuid)
        let loadout = 0
        if (playerTabData?.gear_id) {
          loadout += this.gearsById[playerTabData.gear_id].cost
        }
        if (playerTabData?.weapon_id) {
          loadout += this.weaponsById[playerTabData.weapon_id].cost
        }
        if (loadout) {
          if (acc === null) {
            acc = 0
          }
          acc += loadout
        }
        return acc
      }, null)
    },
    calcRoundWin(team, roundNum, scrim, scores) {
      const teamPosition = scrim.pov_grid === team.grid ? 'left' : 'right'
      if (!scores?.[roundNum + 1]?.[teamPosition]) {
        return null
      }
      return scores[roundNum + 1][teamPosition] > scrim.round_scores[roundNum][teamPosition]
    },
  },
}
</script>

<style lang="scss" scoped></style>
